const testingApiHost = 'https://api.snabble-testing.io';

const replaceWithApi = (location, url) => {
  if (url.slice(0, 4) === 'http' || !url.startsWith('/') || !location.host) {
    return url;
  }

  if (location.host.indexOf('snabble') === -1) {
    return testingApiHost + url;
  }

  const host = location.host.replace(/^gatekeeper./, 'api.');
  return `${location.protocol}//${host}${url}`;
};

const addApiHost = replaceWithApi.bind(window, window.location);

export { replaceWithApi, addApiHost };
