import ProjectSettings from '../ProjectSettings';

const selectLogo = (project) => {
  if (ProjectSettings.logos[project]) return ProjectSettings.logos[project];
  return ProjectSettings.logos.default;
};

const Logo = ({ project }) => selectLogo(project);

export default Logo;
