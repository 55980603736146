import process from './process';
import notification from './notification';
import auth from './auth';
import loadingIndicator from './loadingIndicator';
import offlinePage from './offlinePage';

const stateObj = (s = {
  auth: {
    token: {},
    gatekeeperToken: '',
    isAuthorized: false,
  },
  process: {
    details: {},
    requiresSupervising: null,
    isLoading: false,
    hasInternalServerError: false,
    succeeded: null,
    failed: null,
    aborted: null,
  },
  notification: {
    message: null,
    isVisible: false,
    isError: false,
  },
  loadingIndicator: {
    isVisible: false,
    type: '',
  },
  offlinePage: {
    isVisible: false,
  },
}, action) => Object.assign({}, s, {
  auth: auth(s.auth, action),
  process: process(s.process, action),
  notification: notification(s.notification, action),
  loadingIndicator: loadingIndicator(s.loadingIndicator, action),
  offlinePage: offlinePage(s.offlinePage, action),
});


export default stateObj;
