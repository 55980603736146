
import { connect } from 'react-redux';
import { sendHeartbeat } from '../actions';
import Heartbeat from '../components/Heartbeat';

const HeartbeatContainer = connect(
  null,
  dispatch => ({
    sendHeartbeat: (project, id) => dispatch(sendHeartbeat(project, id)),
  }),
)(Heartbeat);

export default HeartbeatContainer;
