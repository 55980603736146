import React from 'react';
import PropTypes from 'prop-types';


class ScreenSaver extends React.PureComponent {
  constructor(props) {
    super(props);
    this.video = React.createRef();
  }

  componentDidMount() {
    if (this.props.videoSrc === '') return;
    this.video.current.addEventListener('ended', () => { this.handleVideoEnd(); });
  }

  componentDidUpdate() {
    if (this.props.isVisible && this.props.videoSrc !== '') {
      this.timer = setTimeout(() => {
        this.video.current.play()
          .then(
            () => { },
            () => { this.handleVideoEnd(); }, // just in case playing video fails
          );
      }, 250);
    }
  }

  componentWillUnmount() {
    if (this.props.videoSrc === '') return;
    clearTimeout(this.timer);
    this.video.current.removeEventListener('ended', () => { this.handleVideoEnd(); });
  }

  handleVideoEnd() {
    this.timer = setTimeout(() => {
      this.props.onEnd();
    }, 250);
  }

  render() {
    if (this.props.videoSrc === '') return null;

    return (
      <div className={this.props.isVisible ? 'screensaver screensaver--is-visible' : 'screensaver'}>
        <video width={this.props.videoDimensions.width} height={this.props.videoDimensions.height} muted className="screensaver__video" ref={this.video}>
          <source src={this.props.videoSrc} type="video/mp4" />
        </video>
      </div>
    );
  }
}

ScreenSaver.defaultProps = {
  videoSrc: '',
  videoDimensions: {
    height: 1080,
    width: 1080,
  },
};

ScreenSaver.propTypes = {
  onEnd: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  videoSrc: PropTypes.string,
  videoDimensions: PropTypes.objectOf(PropTypes.number),
};

export default ScreenSaver;
