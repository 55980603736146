import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { ReactComponent as PosIcon } from '../images/knauber-kasse.svg';

class WrongPaymentMethod extends React.PureComponent {
  componentDidMount() {
    this.timer = setTimeout(() => {
      this.props.goBack();
    }, 60000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return (
      <div className="wrong-payment-method">
        <div className="wrong-payment-method__content">
          <PosIcon className="wrong-payment-method__icon" />
          <h1 className="wrong-payment-method__headline"><Translate id="wrong-payment-method.headline" /></h1>
          <p className="wrong-payment-method__text"><Translate id="wrong-payment-method.text" /></p>
          <button className="wrong-payment-method__btn" onClick={() => this.props.goBack()}><Translate id="btn.back" /></button>
        </div>
      </div>
    );
  }
}

WrongPaymentMethod.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default WrongPaymentMethod;
