import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ProjectSettings from '../ProjectSettings';

class ThemeColors extends PureComponent {
  constructor(props) {
    super(props);

    const { project, addTranslationForLanguage, setActiveLanguage } = props;
    const language = ProjectSettings.texts[project];
    if (language) {
      addTranslationForLanguage(language, project);
      setActiveLanguage(project);
    }
  }
  render() {
    const {
      project, children,
    } = this.props;
    const projectTheme = ProjectSettings.styles[project];
    return (
      <div className="theme" style={projectTheme}>
        {children}
      </div>
    );
  }
}

ThemeColors.defaultProps = {
  children: null,
  project: '',
};

ThemeColors.propTypes = {
  children: PropTypes.element,
  project: PropTypes.string,
  addTranslationForLanguage: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
};

export default withLocalize(ThemeColors);
