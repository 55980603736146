import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Scanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleValue = this.handleValue.bind(this);
  }
  componentDidMount() {
    document.addEventListener('keypress', this.handleKeyPress);
  }
  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeyPress);
  }
  handleValue(value) {
    this.setState({ value: '' });
    this.props.onContentReceive(value);
  }
  handleKeyPress(event) {
    const { code, keyCode } = event;
    const { value } = this.state;
    const keyValue = String.fromCharCode(keyCode);

    if (code === 'Enter') {
      return this.handleValue(value);
    }

    if (keyValue) {
      return this.setState({ value: value + keyValue });
    }

    return null;
  }
  render() {
    return null;
  }
}

Scanner.propTypes = {
  onContentReceive: PropTypes.func.isRequired,
};

export default Scanner;
