import { connect } from 'react-redux';
import Logout from '../components/Logout';

const LogoutContainer = connect(state => ({
  logoutRequired: !!state.auth.failed && !!state.auth.isAuthorized,
  logoutCallback: () => {
    document.cookie = 'jwt_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    window.location.reload(true);
  },
}))(Logout);

export default LogoutContainer;
