import { connect } from 'react-redux';
import { resetNotification } from '../actions';
import Notification from '../components/Notification';

const NotificationContainer = connect(
  state => ({
    isVisible: state.notification.isVisible,
    message: state.notification.message,
  }),
  dispatch => ({
    onClose: () => dispatch(resetNotification()),
  }),
)(Notification);


export default NotificationContainer;
