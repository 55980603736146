export default (notification, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION': {
      return Object.assign(
        {}, notification,
        {
          isVisible: true,
          message: action.payload,
        },
      );
    }

    case 'RESET_NOTIFICATION': {
      return Object.assign(
        {}, notification,
        {
          message: null,
          isVisible: false,
        },
      );
    }

    default:
      return notification;
  }
};
