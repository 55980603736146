import { approveProcess, getProcess, resetProcess } from './process';
import { showNotification, resetNotification } from './notification';
import loginWithScannedCredentials from './login';
import { showOfflinePage, hideOfflinePage } from './offline';
import sendHeartbeat from './heartbeat';

function showLoadingIndicator(type) {
  return { type: 'SHOW_LOADING_INDICATOR', payload: type };
}

function hideLoadingIndicator() {
  return { type: 'HIDE_LOADING_INDICATOR' };
}

export {
  approveProcess, showNotification, resetNotification, loginWithScannedCredentials, getProcess,
  showLoadingIndicator, hideLoadingIndicator, showOfflinePage, hideOfflinePage,
  sendHeartbeat, resetProcess,
};
