import React, { PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';


class OfflineView extends PureComponent {
  render() {
    if (!this.props.isVisible) return <span />;

    return (
      <div className="offline">
        <div className="offline__content">
          <h1 className="offline__headline"><Translate id="error.offline-headline" /></h1>
          <p className="offline__text"><Translate id="error.offline-text" /></p>
        </div>
      </div>
    );
  }
}

OfflineView.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default OfflineView;
