import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StartView from '../views/StartView';
import ApprovalResultView from '../views/ApprovalResultView';
import ApprovalRequiredView from '../views/ApprovalRequiredView';
import ErrorView from '../views/ErrorView';
import LoadingView from '../views/LoadingView';
import WrongPaymentMethodView from '../views/WrongPaymentMethodView';


const isUUID = value => !!value.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/);
const isMultiLineBarcode = value => !!value.match(/^(\(?\+?[0-9]*\)?)?[0-9_\- \\(\\)]*$/gm);

class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processID: '',
      showScreen: 'start',
    };

    this.handleContentReceive = this.handleContentReceive.bind(this);
    this.handleRestart = this.handleRestart.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.pollingTimeout);
  }

  handleContentReceive(value) {
    if (isUUID(value)) {
      this.setState({ processID: value, showScreen: 'other' });
      this.props.approveProcess(value);
      this.processPolling();
      return;
    }
    if (isMultiLineBarcode(value)) {
      this.setState({ showScreen: 'wrongPaymentMethod' });
      return;
    }
    if (value) {
      this.setState({ showScreen: 'invalidCode' });
    }
  }

  processPolling() {
    this.pollingTimeout = setTimeout(() => {
      if (this.props.polling === 'polling') {
        this.props.pollProcess(this.state.processID);
      }
      if (this.props.polling !== 'finished') {
        this.processPolling();
      }
    }, 2500);
  }

  handleRestart() {
    clearTimeout(this.pollingTimeout);
    this.props.handleReset();
    this.setState({ processID: '', showScreen: 'start' });
  }

  render() {
    const {
      project, errorIdentifier, isHidden,
      showSuccessMessage, showErrorMessage,
      showApprovalRequired,
    } = this.props;

    if (isHidden) return null;

    if (this.state.showScreen === 'start') {
      return <StartView handleContentReceive={this.handleContentReceive} project={project} />;
    }

    if (this.state.showScreen === 'wrongPaymentMethod') {
      return (<WrongPaymentMethodView goBack={() => this.setState({ showScreen: 'start' })} />);
    }

    if (this.state.showScreen === 'invalidCode') {
      return (<ErrorView
        text="error.invalid-code"
        goBack={() => this.setState({ showScreen: 'start' })} />);
    }

    if (showErrorMessage) {
      return <ErrorView text={errorIdentifier} goBack={() => this.handleRestart()} />;
    }

    if (showApprovalRequired) {
      return (
        <ApprovalRequiredView
          processID={this.state.processID}
          finishStep={() => this.handleRestart()}
        />
      );
    }

    if (showSuccessMessage) {
      return (
        <ApprovalResultView
          finishStep={() => this.handleRestart()}
        />
      );
    }

    return (<LoadingView isVisible type="approval" />);
  }
}

Wizard.defaultProps = {
  showApprovalRequired: null,
  showSuccessMessage: false,
  showErrorMessage: false,
  errorIdentifier: '',
};

Wizard.propTypes = {
  project: PropTypes.string.isRequired,
  approveProcess: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
  pollProcess: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  errorIdentifier: PropTypes.string,
  showApprovalRequired: PropTypes.bool,
  polling: PropTypes.string.isRequired,
};

export default Wizard;
