
import { connect } from 'react-redux';
import { approveProcess, getProcess, resetProcess } from '../actions';
import Wizard from '../components/Wizard';

function chooseMessage(process) {
  if (process.failed) return 'error.payment';
  if (process.hasInternalServerError) return 'error.server';
  if (!process.found) return 'error.process-not-found';
  if (process.aborted) return 'error.aborted';
  return '';
}

function hasError(process) {
  if (!process) return false;
  return (
    process.failed
    || process.hasInternalServerError
    || process.found === false
    || process.aborted
  );
}

function handleLoadingScreen(process) {
  if (!process || process.aborted) return false;
  if (
    process.details.state === 'granted' &&
    (process.details.paymentState === 'pending' || process.details.paymentState === 'processing')
  ) return true;

  if (process.details.state === 'processing') return true;

  if (process.isLoading && process.requiresSupervising === null) return true;
  return false;
}

function isApprovalRequired(process) {
  return process.requiresSupervising &&
        ['failed', 'succeeded', 'aborted'].indexOf(process.details.state) === -1;
}


function polling(process) {
  if (hasError(process) || process.succeeded) {
    return 'finished';
  }

  if (
    process.patched &&
    (isApprovalRequired(process) || handleLoadingScreen(process))
  ) {
    return 'polling';
  }

  return 'waiting';
}

const WizardContainer = connect(
  state => ({
    isHidden: !state.auth.isAuthorized,
    showSuccessMessage: state.process.succeeded,
    showLoadingScreen: handleLoadingScreen(state.process),
    showErrorMessage: hasError(state.process),
    errorIdentifier: chooseMessage(state.process),
    showApprovalRequired: isApprovalRequired(state.process),
    polling: polling(state.process),
  }),
  (dispatch, props) => ({
    approveProcess: (id) => {
      dispatch(approveProcess(props.project, id));
    },
    pollProcess: (id) => {
      if (!id) return;
      dispatch(getProcess(props.project, id));
    },
    handleReset: () => {
      dispatch(resetProcess());
    },
  }),
)(Wizard);

export default WizardContainer;
