export default (offlinePage, action) => {
  switch (action.type) {
    case 'SHOW_OFFLINE_PAGE': {
      return Object.assign(
        {}, offlinePage,
        {
          isVisible: true,
        },
      );
    }

    case 'HIDE_OFFLINE_PAGE': {
      return Object.assign(
        {}, offlinePage,
        {
          isVisible: false,
        },
      );
    }

    default:
      return offlinePage;
  }
};
