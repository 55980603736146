import { addApiHost } from './urls';

function parseBody(response) {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('json') !== -1) {
    return response.json();
  }
  return Promise.resolve({});
}

function requestHandler(resolve, reject) {
  return (response) => {
    if (response.status >= 200 && response.status < 300) {
      parseBody(response).then(resolve);
      return;
    }

    if (response.status === 409) {
      parseBody(response).then(body => reject({ body, status: 'conflict' }));
      return;
    }

    if (response.status === 401 || response.status === 403) {
      reject({
        status: 'forbidden',
      });
      return;
    }

    if (response.status === 404) {
      reject({
        status: 'not_found',
      });
      return;
    }

    if (response.status === 503) {
      reject({
        status: 'offline',
      });
      return;
    }

    if (response.status >= 500) {
      reject({
        status: 'internal_server_error',
      });
      return;
    }

    reject({
      status: 'unexpected_error',
    });
  };
}

function errorHandler(reject) {
  return (error) => {
    reject({
      error,
      status: 'request_error',
    });
  };
}

const patch = (getState, url, body) => new Promise((resolve, reject) => {
  fetch(
    addApiHost(url),
    {
      body: JSON.stringify(body),
      headers: {
        'Client-Token': getState().auth.gatekeeperToken,
        Accept: 'application/json',
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
    },
  )
    .then(
      requestHandler(resolve, reject),
      errorHandler(reject),
    );
});

const put = (getState, url, body) => new Promise((resolve, reject) => {
  const request = {
    headers: {
      'Client-Token': getState().auth.gatekeeperToken,
      Accept: 'application/json',
    },
    method: 'PUT',
  };
  if (body) {
    request.body = JSON.stringify(body);
    request.headers['Content-Type'] = 'application/json';
  }
  fetch(
    addApiHost(url),
    request,
  )
    .then(
      requestHandler(resolve, reject),
      errorHandler(reject),
    );
});

const get = (getState, url) => new Promise((resolve, reject) => {
  fetch(
    addApiHost(url),
    {
      headers: {
        'Client-Token': getState().auth.gatekeeperToken,
        Accept: 'application/json',
      },
      method: 'GET',
    },
  )
    .then(
      requestHandler(resolve, reject),
      errorHandler(reject),
    );
});

export { patch, put, get };
