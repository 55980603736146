import React from 'react';
import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import * as serviceWorker from './serviceWorkerSnabble';
import reducedStore from './reducers';
import { decodeToken } from './helper/token';

import './index.scss';
import App from './App';

/* eslint react/jsx-filename-extension: "off" */

const store = createStore(
  reducedStore,
  applyMiddleware(thunkMiddleware),
);

const token = decodeToken(document.cookie);
if (token) {
  store.dispatch({ type: 'ACQUIRED_TOKEN', payload: token });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
