import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import QRCode from 'qrcode.react';
import { ReactComponent as WarningIcon } from '../images/bell.svg';

const ApprovalRequiredView = ({ finishStep, processID }) => (
  <div className="approval-result approval-result--warning">
    <div className="approval-result__content">
      <WarningIcon />
      <h1 className="approval-result__headline"><Translate id="approval-result.wait" /></h1>
      <p className="approval-result__text"><Translate id="approval-result.wait-instruction" /></p>
      <div className="approval-result__code"><QRCode value={processID} size={67} bgColor="transparent" /></div>
      <button className="approval-result__btn" onClick={() => { finishStep(); }}>
        <Translate id="btn.back" />
      </button>
    </div>
  </div>
);

ApprovalRequiredView.propTypes = {
  processID: PropTypes.string.isRequired,
  finishStep: PropTypes.func.isRequired,
};

export default ApprovalRequiredView;
