import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

const Instruction = ({ smallerFont }) => (
  <div className={smallerFont ? 'instruction instruction--small' : 'instruction'}>
    <div className="instruction__content">
      <h1 className="instruction__headline"><Translate id="instruction.headline" /></h1>
      <p className="instruction__text"><Translate id="instruction.text" /></p>
    </div>
  </div>
);

Instruction.defaultProps = {
  smallerFont: false,
};

Instruction.propTypes = {
  smallerFont: PropTypes.bool,
};

export default Instruction;
