import { put } from '../helper/requests';
import { hideOfflinePage, showOfflinePage } from './offline';
import failedAuthorization from './auth';

function sendHeartbeat(project, id) {
  const url = `/${project}/supervising/gatekeeper/id/${id}/status`;
  return (dispatch, getState) => {
    put(getState, url).then(
      () => {
        dispatch(hideOfflinePage());
      },
      ({ status }) => {
        switch (status) {
          case 'forbidden':
            dispatch(failedAuthorization());
            dispatch(hideOfflinePage());
            break;
          default:
            dispatch(showOfflinePage());
        }
      },
    );
  };
}

export default sendHeartbeat;
