export default (loadingIndicator, action) => {
  switch (action.type) {
    case 'SHOW_LOADING_INDICATOR': {
      return Object.assign(
        {}, loadingIndicator,
        {
          isVisible: true,
          type: action.payload,
        },
      );
    }

    case 'HIDE_LOADING_INDICATOR': {
      return Object.assign(
        {}, loadingIndicator,
        {
          isVisible: false,
          type: '',
        },
      );
    }

    default:
      return loadingIndicator;
  }
};
