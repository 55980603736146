import React from 'react';
import { ReactComponent as SnabbleLogo } from './images/logo-snabble.svg';
import pflanzenKoelleTexts from './text/pflanzen-koelle-95t309.json';


export default {
  logos: {
    default: <SnabbleLogo className="logo" />,
  },
  styles: {
    'pflanzen-koelle-95t309': {
      '--theme-primary-color': '#61993b',
    },
  },
  videoDimensions: {
    default: {
      height: 1080,
      width: 1080,
    },
  },
  videos: {
    default: '',
  },
  // do not forget to initialize new language in App.jsx
  texts: {
    'pflanzen-koelle-95t309': pflanzenKoelleTexts,
  },
};
