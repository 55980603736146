import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from '../images/error.svg';

class ErrorView extends React.PureComponent {
  componentDidMount() {
    this.timer = setTimeout(() => {
      this.props.goBack();
    }, this.props.timeout);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { detail, goBack, text } = this.props;

    return (
      <div className="error">
        <div className="error__content">
          <ErrorIcon className="error__icon" />
          <h1 className="error__headline"><Translate id="error.headline" /></h1>
          <p className="error__text"><Translate id={text} /></p>
          {!!detail && <p className="error__detail"><Translate id="error.code" data={{ detail }} /></p>}
          <button className="error__btn" onClick={() => goBack()}><Translate id="btn.back" /></button>
        </div>
      </div>
    );
  }
}

ErrorView.defaultProps = {
  detail: null,
  text: 'error.text',
  timeout: 60000,
};

ErrorView.propTypes = {
  detail: PropTypes.string,
  text: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  timeout: PropTypes.number,
};

export default ErrorView;
