import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';


class NightlyLogout extends React.PureComponent {
  constructor() {
    super();
    this.scheduleLogout = this.scheduleLogout.bind(this);
  }

  componentDidMount() {
    this.scheduleLogout();
  }

  scheduleLogout() {
    const now = new Moment();
    const fromMoment = new Moment().startOf('day').add(this.props.from, 'hours');
    const afterMoment = new Moment().startOf('day').add(this.props.till, 'hours');
    const lastLogout = this.props.getLastLogout();
    const delay = 24 - (this.props.till - this.props.from);

    if (
      this.props.isAuthorized &&
      now.isAfter(fromMoment) &&
      now.isBefore(afterMoment) &&
      (!lastLogout || lastLogout.add(delay, 'hours').isBefore(now))
    ) {
      this.props.logoutCallback();
      return;
    }
    setTimeout(() => this.scheduleLogout(), 45 * 60 * 1000);
  }
  render() {
    return null;
  }
}

NightlyLogout.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  from: PropTypes.number.isRequired,
  till: PropTypes.number.isRequired,
  logoutCallback: PropTypes.func.isRequired,
  getLastLogout: PropTypes.func.isRequired,
};


export default NightlyLogout;
