import { connect } from 'react-redux';
import Moment from 'moment';
import NightlyLogout from '../components/NightlyLogout';
import authorizationFailed from '../actions/auth';
import { lastAutomatedLogout } from '../helper/cookies';

const NightlyLogoutContainer = connect(
  state => ({
    isAuthorized: state.auth.isAuthorized,
    getLastLogout: lastAutomatedLogout,
  }),
  dispatch => ({
    logoutCallback: () => {
      document.cookie = `lastAutomatedLogout=${new Moment().toISOString()}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      dispatch(authorizationFailed());
    },
  }),
)(NightlyLogout);

export default NightlyLogoutContainer;

