import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { ReactComponent as LoadingIcon } from '../images/spinner.svg';

const LoadingView = ({ isVisible, type }) => {
  if (!isVisible) return null;

  return (
    <div className="loading">
      <div className="loading__content">
        <LoadingIcon className="loading__icon animation animation--spin" />
        <h1 className="loading__headline"><Translate id="loading.headline" /></h1>
        {type === 'approval' && <p className="loading__text"><Translate id="loading.text" /></p>}
      </div>
    </div>
  );
};

LoadingView.defaultProps = {
  type: '',
};

LoadingView.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

export default LoadingView;
