import React from 'react';
import PropTypes from 'prop-types';
import { getHostname } from '../helper/cookies';


const heartbeatInterval = 10000;

class Heartbeat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hostname: getHostname(props.query),
    };
    this.send = this.send.bind(this);
  }

  componentDidMount() {
    this.send();
  }

  send() {
    this.props.sendHeartbeat(this.props.project, this.state.hostname);

    setTimeout(() => {
      this.send();
    }, heartbeatInterval);
  }

  render() {
    return null;
  }
}

Heartbeat.propTypes = {
  project: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  sendHeartbeat: PropTypes.func.isRequired,
};

export default Heartbeat;
