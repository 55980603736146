import queryString from 'query-string';
import uuidv4 from 'uuid/v4';
import Moment from 'moment';

function getHostname(query) {
  let name = '';
  const values = queryString.parse(query);
  if (values.hostname) {
    name = values.hostname;
  } else if (document.cookie.split(';').filter(item => item.trim().startsWith('hostname=')).length) {
    // See https://developer.mozilla.org/en-US/docs/Web/API/document/cookie
    name = document.cookie.replace(/(?:(?:^|.*;\s*)hostname\s*=\s*([^;]*).*$)|^.*$/, '$1');
  } else {
    name = uuidv4();
  }
  document.cookie = `hostname=${name};max-age=31536000`;
  return name;
}


function lastAutomatedLogout() {
  const last = new Moment(document.cookie.replace(/(?:(?:^|.*;\s*)lastAutomatedLogout\s*=\s*([^;]*).*$)|^.*$/, '$1'));
  if (last.isValid()) {
    return last;
  }
  return null;
}

export { getHostname, lastAutomatedLogout };
