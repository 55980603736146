import PropTypes from 'prop-types';

const Logout = (props) => {
  if (props.logoutRequired) {
    props.logoutCallback();
  }
  return null;
};

Logout.propTypes = {
  logoutRequired: PropTypes.bool.isRequired,
  logoutCallback: PropTypes.func.isRequired,
};


export default Logout;

