
import { connect } from 'react-redux';
import { loginWithScannedCredentials } from '../actions';
import LoginView from '../views/LoginView';

const LoginContainer = connect(
  state => ({
    isAuthorized: state.auth.isAuthorized,
    isLoading: state.loadingIndicator.isVisible,
  }),
  dispatch => ({
    onScanSuccess: credentials => dispatch(loginWithScannedCredentials(credentials)),
  }),
)(LoginView);

export default LoginContainer;
