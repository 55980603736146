import React from 'react';
import { Translate } from 'react-localize-redux';

const NotFoundView = () => (
  <div className="error error--warning">
    <div className="error__content">
      <h1 className="error__headline"><Translate id="error.not-found-headline" /></h1>
      <p className="error__text"><Translate id="error.not-found-text" /></p>
    </div>
  </div>
);

export default NotFoundView;
