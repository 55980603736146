import React from 'react';
import PropTypes from 'prop-types';
import Instruction from '../components/Instruction';
import Scanner from '../components/Scanner';
import Logo from '../components/Logo';
import ScreenSaver from '../components/ScreenSaver';
import Advertising from '../components/Advertising';
import ProjectSettings from '../ProjectSettings';


const selectVideo = (project) => {
  if (ProjectSettings.videos[project]) return ProjectSettings.videos[project];
  return ProjectSettings.videos.default;
};

const selectDimension = (project) => {
  if (ProjectSettings.videoDimensions[project]) return ProjectSettings.videoDimensions[project];
  return ProjectSettings.videoDimensions.default;
};

class StartView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false,
      videoSrc: selectVideo(props.project),
      videoDimensions: selectDimension(props.project),
      showAdvertising: true,
    };
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  startTimer() {
    // no video no need to handle visibility :)
    if (!this.state.videoSrc) return;
    this.timer = setTimeout(() => this.handleVisibility(), 20000);
  }

  handleVisibility() {
    this.setState({
      showAdvertising: !this.state.showAdvertising,
      showVideo: !this.state.showVideo,
    });
  }

  render() {
    const { handleContentReceive, project } = this.props;

    return (
      <React.Fragment>
        <Scanner onContentReceive={value => handleContentReceive(value)} />

        <ScreenSaver
          project={project}
          isVisible={this.state.showVideo}
          videoSrc={this.state.videoSrc}
          videoDimensions={this.state.videoDimensions}
          onEnd={() => {
            this.handleVisibility();
            this.startTimer();
          }}
        />

        <Advertising isVisible={this.state.showAdvertising} />

        {this.state.showAdvertising &&
          <Logo project={project} />
        }

        <Instruction smallerFont={this.state.showVideo} />
      </React.Fragment>
    );
  }
}

StartView.propTypes = {
  handleContentReceive: PropTypes.func.isRequired,
  project: PropTypes.string.isRequired,
};

export default StartView;
