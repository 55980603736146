import { patch, get } from '../helper/requests';
import { showOfflinePage } from './offline';
import failedAuthorization from './auth';

function patchingProcess() {
  return {
    type: 'PATCHING_PROCESS',
  };
}

function patchedProcess(process) {
  return {
    type: 'PATCHED_PROCESS',
    payload: process,
  };
}

function gettingProcess() {
  return {
    type: 'GETTING_PROCESS',
  };
}

function gotProcess(process) {
  return {
    type: 'GOT_PROCESS',
    payload: process,
  };
}

function processNotFound() {
  return {
    type: 'PROCESS_NOT_FOUND',
  };
}

function receivedProcessError() {
  return {
    type: 'RECEIVED_PROCESS_ERROR',
  };
}

function resetProcess() {
  return {
    type: 'RESET_PROCESS',
  };
}

function getProcess(project, id) {
  const url = `/${project}/supervising/processes/checkout/${id}`;
  return (dispatch, getState) => {
    dispatch(gettingProcess());

    get(getState, url)
      .then(
        (process) => {
          dispatch(gotProcess(process));
        },
        ({ status }) => {
          switch (status) {
            case 'forbidden':
              dispatch(failedAuthorization());
              break;
            case 'not_found':
              dispatch(processNotFound());
              break;
            case 'offline':
              dispatch(showOfflinePage());
              break;
            default: // 'internal_server_error' && 'unexpected_error'
              dispatch(receivedProcessError());
          }
        },
      );
  };
}

function approveProcess(project, id) {
  const url = `/${project}/supervising/processes/checkout/${id}`;
  return (dispatch, getState) => {
    dispatch(patchingProcess());
    patch(getState, url, { state: 'granted' })
      .then(
        (process) => {
          dispatch(patchedProcess(process));
        },
        ({ status, body }) => {
          switch (status) {
            case 'conflict':
              dispatch(patchedProcess(body));
              break;
            case 'forbidden':
              dispatch(failedAuthorization());
              break;
            case 'not_found':
              dispatch(processNotFound());
              break;
            case 'offline':
              dispatch(showOfflinePage());
              break;
            default: // 'internal_server_error' && 'unexpected_error'
              dispatch(receivedProcessError());
          }
        },
      );
  };
}


export { approveProcess, getProcess, resetProcess };
