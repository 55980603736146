import aesjs from 'aes-js';
import pbkdf2 from 'pbkdf2';
import { unpad } from 'pkcs7';
import { showNotification, resetNotification } from './notification';
import { showOfflinePage } from './offline';
import { showLoadingIndicator, hideLoadingIndicator } from './index';
import { tokenDataFromString } from '../helper/token';

function acquiredToken(token) {
  return { type: 'ACQUIRED_TOKEN', payload: token };
}

function processingLoginCredentials(processing) {
  return { type: 'PROCESSING_LOGIN_CREDENTIALS', payload: processing };
}

function login(dispatch, credentials) {
  dispatch(processingLoginCredentials(true));
  fetch(
    '/login',
    {
      body: credentials,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/jwt',
      },
      method: 'POST',
    },
  ).then((response) => {
    dispatch(processingLoginCredentials(false));
    dispatch(hideLoadingIndicator());
    if (response.status === 200) {
      response.text()
        .then(token => dispatch(acquiredToken(tokenDataFromString(token))));
      return;
    }
    if (response.status === 403) {
      dispatch(showNotification('Wrong Credentials'));
      return;
    }
    if (response.status === 503) {
      dispatch(showOfflinePage());
    }
  });
}

function getKeys() {
  try {
    return JSON.parse(window.localStorage.deviceKeys);
  } catch (e) {
    return {};
  }
}

function setKeys(keys) {
  window.localStorage.deviceKeys = JSON.stringify(keys);
}

function getKey(keyId) {
  const keys = getKeys();
  return keys[keyId];
}

function setKey(keyId, key) {
  const keys = getKeys();
  keys[keyId] = key;
  setKeys(keys);
}

function deleteKey(keyId) {
  const keys = getKeys();
  delete keys[keyId];
  setKeys(keys);
}

function clearKeys() {
  setKeys({});
}

function decryptBuffers(passwordBuffer, cipherBuffer) {
  const salt = cipherBuffer.slice(8, 16);
  const iv = cipherBuffer.slice(16, 32);
  const encrypted = cipherBuffer.slice(32);

  try {
    const derivedKey = pbkdf2.pbkdf2Sync(passwordBuffer, salt, 10000, 32, 'sha256');
    const aesCbc = new aesjs.ModeOfOperation.cbc(derivedKey, iv); // eslint-disable-line new-cap
    const dec = aesCbc.decrypt(encrypted);
    return aesjs.utils.utf8.fromBytes(unpad(dec));
  } catch (e) {
    return undefined;
  }
}

// - We use an aes-256-cbc cipher.
// - The key is generated out of the provided passphrase using
//   pbkdf2 algorithm with a random salt, sha256 and 10000 iterations.
// - The plaintext is padded with pkcs7.
// - The result is concatenated in the following form:
//   base64('Salted__' + salt(8) + iv(8) + encrypted)
function decrypt(passwordString, cipherBase64String) {
  return decryptBuffers(Buffer.from(passwordString), Buffer.from(cipherBase64String, 'base64'));
}

const handleScannedData = (dispatch, getState, scannedDataRaw) => new Promise(() => {
  dispatch(resetNotification());
  dispatch(showLoadingIndicator());

  if (!scannedDataRaw.startsWith('{')) {
    login(dispatch, scannedDataRaw);
    dispatch(hideLoadingIndicator());
    return;
  }

  let scannedObject;
  try {
    scannedObject = JSON.parse(scannedDataRaw);
  } catch (e) {
    dispatch(showNotification(`Bad Token ${e}`));
    dispatch(hideLoadingIndicator());
    return;
  }

  switch (scannedObject.type) {
    case 'pwd':
      login(dispatch, scannedObject.data);
      return;
    case 'aes256pwd': {
      const key = getKey(scannedObject.keyid);
      if (!key) {
        dispatch(showNotification(`Missing device key: ${scannedObject.keyid}`));
        dispatch(hideLoadingIndicator());
        return;
      }
      const credentials = decrypt(key, scannedObject.data);
      if (!credentials) {
        dispatch(showNotification('Bad Token Decryption'));
        dispatch(hideLoadingIndicator());
        return;
      }
      login(dispatch, credentials);
      return;
    }
    case 'setkey':
      setKey(scannedObject.keyid, scannedObject.data);
      dispatch(showNotification(`Key saved: ${scannedObject.keyid}`));
      dispatch(hideLoadingIndicator());
      return;
    case 'delkey':
      deleteKey(scannedObject.keyid);
      dispatch(showNotification(`Key removed: ${scannedObject.keyid}`));
      dispatch(hideLoadingIndicator());
      return;
    case 'clearkeys':
      clearKeys();
      dispatch(showNotification('Removed all keys.'));
      dispatch(hideLoadingIndicator());
      return;
    default:
      dispatch(showNotification(`Unknown type ${scannedObject.type}`));
      dispatch(hideLoadingIndicator());
  }
});

function loginWithScannedCredentials(scannedData) {
  return (dispatch, getState) => handleScannedData(dispatch, getState, scannedData);
}

export default loginWithScannedCredentials;
