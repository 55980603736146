
import { connect } from 'react-redux';
import LoadingView from '../views/LoadingView';

const LoadingContainer = connect(state => ({
  isVisible: state.loadingIndicator.isVisible,
  type: state.loadingIndicator.type,
}))(LoadingView);

export default LoadingContainer;
