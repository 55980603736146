import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { ReactComponent as AppleIcon } from '../images/download-app-store-de.svg';
import { ReactComponent as AndroidIcon } from '../images/download-play-store-de.svg';


const Advertising = ({ isVisible }) => (
  <div className={isVisible ? 'advertising advertising--is-visible' : 'advertising'}>
    <div className="advertising__content">
      <p className="advertising__text"><Translate id="advertising.text" /></p>

      <div className="advertising__bar">
        <p className="advertising__url"><Translate id="advertising.url" /></p>
        <AndroidIcon className="advertising__icon" />
        <AppleIcon className="advertising__icon" />
      </div>
    </div>
  </div>
);

Advertising.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default Advertising;
