const defaultValues = {
  requiresSupervising: null,
  details: {},
  isLoading: false,
  found: undefined,
  hasInternalServerError: false,
  succeeded: null,
  failed: null,
  aborted: null,
  patched: false,
};

function receiveProcess(action) {
  return {
    details: action.payload,
    isLoading: false,
    found: true,
    requiresSupervising: action.payload.state === 'pending',
    succeeded: action.payload.state === 'succeeded',
    failed: action.payload.state === 'failed' || action.payload.state === 'rejected',
    aborted: action.payload.state === 'aborted',
  };
}

export default (process, action) => {
  switch (action.type) {
    case 'RECEIVED_PROCESS_ERROR': {
      return Object.assign(
        {}, process,
        {
          isLoading: false,
          hasInternalServerError: true,
        },
      );
    }

    case 'PROCESS_NOT_FOUND': {
      return Object.assign({}, process, defaultValues, { found: false });
    }

    case 'RESET_PROCESS': {
      return Object.assign({}, process, defaultValues);
    }

    case 'GOT_PROCESS': {
      return Object.assign(
        {}, process,
        receiveProcess(action),
      );
    }

    case 'PATCHED_PROCESS': {
      return Object.assign(
        {}, process,
        receiveProcess(action),
        {
          patched: true,
        },
      );
    }

    case 'GETTING_PROCESS': {
      return Object.assign(
        {}, process,
        {
          isLoading: true,
          hasInternalServerError: false,
        },
      );
    }

    case 'PATCHING_PROCESS': {
      return Object.assign(
        {},
        process,
        defaultValues,
        {
          isLoading: true,
        },
      );
    }

    default:
      return process;
  }
};
