import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

const onMissingTranslation = ({ translationId }) => translationId;

class Notification extends PureComponent {
  componentDidUpdate() {
    if (this.props.isVisible) {
      window.setTimeout(() => {
        this.props.onClose();
      }, 7500);
    }
  }
  render() {
    const { isVisible, message } = this.props;
    if (!isVisible) {
      return null;
    }

    return (
      <div className="notification">
        <p><Translate id={message} options={{ onMissingTranslation }} /></p>
      </div>
    );
  }
}

Notification.defaultProps = {
  isVisible: false,
  message: '',
};

Notification.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default Notification;
