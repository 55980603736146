import React, { PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import Scanner from '../components/Scanner';

class LoginView extends PureComponent {
  constructor(props) {
    super(props);
    this.handleScannerEvent = this.handleScannerEvent.bind(this);
  }

  handleScannerEvent(value) {
    if (!value) return;
    this.props.onScanSuccess(value);
  }

  render() {
    if (this.props.isAuthorized || this.props.isLoading) return null;

    return (
      <div className="error error--warning">
        <Scanner onContentReceive={value => this.handleScannerEvent(value)} />
        <div className="error__content">
          <h1 className="error__headline"><Translate id="login.headline" /></h1>
          <p className="error__text"><Translate id="login.text" /></p>
        </div>
      </div>
    );
  }
}

LoginView.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onScanSuccess: PropTypes.func.isRequired,
};

export default LoginView;
