import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { ReactComponent as SucceededIcon } from '../images/check.svg';

class ApprovalResultView extends Component {
  componentDidMount() {
    this.hideTimeout = setTimeout(() => this.props.finishStep(), 7500);
  }

  componentWillUnmount() {
    clearTimeout(this.hideTimeout);
  }

  render() {
    return (
      <div className="approval-result">
        <div className="approval-result__content">
          <SucceededIcon className="approval-result__icon approval-result__icon--success" />
          <h1 className="approval-result__headline"><Translate id="approval-result.succeeded" /></h1>
          <p className="approval-result__text"><Translate id="approval-result.succeeded-instruction" /></p>
          <button className="approval-result__btn" onClick={() => { this.props.finishStep(); }}>
            <Translate id="btn.back" />
          </button>
        </div>
      </div>
    );
  }
}

ApprovalResultView.propTypes = {
  finishStep: PropTypes.func.isRequired,
};

export default ApprovalResultView;
