export default (auth, action) => {
  switch (action.type) {
    case 'ACQUIRED_TOKEN': {
      return Object.assign(
        {}, auth,
        {
          token: action.payload,
          gatekeeperToken: action.payload.raw,
          isAuthorized: !!action.payload.raw && !!action.payload.raw.length,
          failed: false,
        },
      );
    }
    case 'AUTHORIZATION_FAILED': {
      return Object.assign(
        {}, auth,
        {
          failed: true,
        },
      );
    }

    default:
      return auth;
  }
};
