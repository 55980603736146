function showOfflinePage() {
  return {
    type: 'SHOW_OFFLINE_PAGE',
  };
}

function hideOfflinePage() {
  return {
    type: 'HIDE_OFFLINE_PAGE',
  };
}

export { showOfflinePage, hideOfflinePage };
