import React from 'react';
import { LocalizeProvider, withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import defaultTexts from './text/default.json';
import Theme from './components/Theme';
import NotificationContainer from './containers/NotificationContainer';
import WizardContainer from './containers/WizardContainer';
import LoginContainer from './containers/LoginContainer';
import LoadingContainer from './containers/LoadingContainer';
import NotFoundView from './views/NotFoundView';
import OfflineContainer from './containers/OfflineContainer';
import HeartbeatContainer from './containers/HeartbeatContainer';
import NightlyLogoutContainer from './containers/NightlyLogoutContainer';
import LogoutContainer from './containers/LogoutContainer';

const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;
const App = () => (
  <LocalizeProvider
    initialize={{
      languages: [
        { name: 'Default', code: 'default' },
        { name: 'Pflanzen-Koelle', code: 'pflanzen-koelle-95t309' },
      ],
      translation: defaultTexts,
      options: {
        defaultLanguage: 'default',
        onMissingTranslation,
        renderToStaticMarkup,
      },
    }}>
    <Router>
      <Switch>
        <Route
          path="/:projectID"
          component={
        ({ match, location }) => (
          <Theme project={match.params.projectID}>
            <React.Fragment>
              <HeartbeatContainer
                project={match.params.projectID}
                query={location.search}
              />
              <NightlyLogoutContainer from={3} till={5} />
              <LogoutContainer />
              <OfflineContainer />
              <LoadingContainer />
              <LoginContainer />
              <WizardContainer project={match.params.projectID} />
            </React.Fragment>
          </Theme>
        )
      } />
        <Route component={() => (<NotFoundView />)} />
      </Switch>
    </Router>

    <NotificationContainer />
  </LocalizeProvider>
);

export default withLocalize(App);
