function resetNotification() {
  return { type: 'RESET_NOTIFICATION' };
}

function showNotification(message) {
  return {
    type: 'SHOW_NOTIFICATION',
    payload: message,
  };
}

export {
  resetNotification, showNotification,
};
